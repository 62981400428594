
const SUPABASE_CONFIG = {
  // supabase_url: "https://xlwteonqlbnklcmmnwrq.supabase.co",
  // supabase_key: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Inhsd3Rlb25xbGJua2xjbW1ud3JxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODk5MjU2NDksImV4cCI6MjAwNTUwMTY0OX0.nZDqOISJnxaFhiB7Pk2LXy6AdzSVIpgMl5YQYKhWkf0",
  // supabase_storage: "https://xlwteonqlbnklcmmnwrq.supabase.co/storage/v1/object/public/",
  supabase_url: "https://ldbsfknvcunezzzgmvbe.supabase.co",
  supabase_key: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImxkYnNma252Y3VuZXp6emdtdmJlIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODYxOTM3MzIsImV4cCI6MjAwMTc2OTczMn0.AsXvNJo8RUSsMz6FRksPhqfBTjGLdShvPA3LUui5y_E",
  supabase_storage: "https://ldbsfknvcunezzzgmvbe.supabase.co/storage/v1/object/public/",
};

export default SUPABASE_CONFIG
