import { ref, toRaw } from "vue";
import useSupabaseAuth from "@/hooks/supabase/auth";
import useItem from '@/composables/item';
import useStorage from '@/composables/storage'
import useCart from '@/composables/cart'
import router from "@/router";

const uItem = useItem();
const uStorage = useStorage();
const uCart = useCart()
const { getUserData } = useSupabaseAuth()

const needSync = ref(true)

const useSync = () => {

  //ここで全てのデータを最新化する必要がある？？
  const setSync = (bool: boolean) => {
    needSync.value = bool
  }

  //データを最新化する
  const syncAllData = async () => {
    const result = await getUserData() //state.userData
    if(!result){
      return router.push('/profile/edit')
    }
    await uItem.initialize()  //uItems.libraries
    await uCart.initialize()  //uCart.cartData
    await uStorage.getStoragePlayHistory()  //uStorage.storage
  }

  return {
    needSync, //ToDo データを更新させるか監視しておく変数
    setSync,
    syncAllData,
  };
}

export default useSync