import { ref, toRaw } from "vue";
import useSupabaseAuth from "@/hooks/supabase/auth";

import { supabase } from '@/hooks/supabase/supabase'
import { useVuelidate } from '@vuelidate/core'
import { required, email, integer } from '@vuelidate/validators'

const { state } = useSupabaseAuth()

const cartData: any = ref({products:[]})

const useCart = () => {

  const initialize = async () => {
    console.log("cart:initialize / データを最新化する")
    await getCartData()
  }

  const getCartData = async () => {

    try {
      const { data, error } = await supabase.from('v_cart')
        .select(`*`)
        .eq('user_id', state.user.uid) //uuidで絞り込み
        .single();
      if (error) throw error
      cartData.value = data
    }catch(e){
      console.log(e)
      //初期化
      cartData.value = {products:[]}
    }finally {
      //
    }

  }

  return {
    cartData,
    initialize,
  };
  
}

export default useCart;