import {
  ref,
} from 'vue'
import { 
  toastController,
} from '@ionic/vue';

const toast = ref()

export default function () {

  const setToast = async (message: string, position: "top" | "bottom" | "middle" | undefined, type: 'nomal' | 'success' | 'danger' = 'nomal', duration = 2000) => {
    
    const cssName = `custom_toast_${type}`;
    toast.value = await toastController.create({
      message: message,
      duration: duration,
      position: position,
      cssClass: cssName,
    });
    await toast.value.present();
  }

  const delToast = async () => {
    if(toast.value)
      await toast.value.dismiss();
  }

  return {
    toast,
    setToast,
    delToast,
  };
}