import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import useSupabaseAuth from "@/hooks/supabase/auth";
import { supabase } from "@/hooks/supabase/supabase"
import useSync from '@/composables/sync';
import useItem from '@/composables/item';
import useLoading from '@/hooks/loading'
import useStorage from '@/composables/storage'
import useToast from '@/hooks/toast'

const { needSync, setSync, syncAllData } = useSync();
const uItem = useItem();
const uStorage = useStorage();
const { setLoading, delLoading } = useLoading()
const { state, getUserData, snsSignupCallback } = useSupabaseAuth()
const { setToast } = useToast()

//商品ページ
const regexCheckShop = /^\/(shop|callback)(?:\/[cp])?/;
//パスワード再設定ページ
const regexCheckResetPassword = /^\/reset-password/;

const requireAuth = async (to: any, from: any, next: any) => {

  if (!state.isLogged) {
    next({ name: 'signin' })
  } else {
    next()

    if(needSync.value){
      
      await setLoading('データを更新中...')

      try {
        //データの最新化
        await syncAllData()
        setSync(false)
        console.log("データの最新化が完了しました")
      }catch(e){
        console.log("データの最新化に失敗しました")
      }finally{
        await delLoading()
      }
      
    }
  }
  
}

const requireNoAuth = async (to: any, from: any, next: any) => {
  
  switch (true) {
    case regexCheckShop.test(to.path):
      next()
      break;
    case regexCheckResetPassword.test(to.path):
      setSync(false);
      next()
      break;
    default:
      if (state.isLogged) {
        if(!state.userData){
          setSync(false)
          next({ name: 'profile-edit' })
        }else{
          next({ name: 'item' })
        }
      } else {
        next()
      }
      break;
  }
  if (state.isLogged) {
    
    if(needSync.value){
      
      await setLoading('データを更新中...')

      try {
        //データの最新化
        await syncAllData()
        setSync(false)
        console.log("データの最新化が完了しました")
      }catch(e){
        console.log("データの最新化に失敗しました")
      }finally{
        await delLoading()
      }
    }

  }
  
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import("@/views/TabsPage.vue"),
    //component: TabsPage,
    children: [
      {
        path: '',
        redirect: 'item',
      },
      {
        name: 'item',
        path: 'item',
        beforeEnter: requireAuth,
        component: () => import('@/views/tabs/Tab1Page.vue'),
        // children: [] //childrenは使えなかった
      },
      {
        name: 'my-library',
        path: 'item/library',
        beforeEnter: requireAuth,
        component: () => import('@/views/item/MyLibraryPage.vue')
      },
      {
        //name: 'my-library-contributor',
        path: 'item/c/:creator_id',
        beforeEnter: requireAuth,
        component: () => import('@/views/item/ContributorPage.vue')
      },
      {
        name: 'shop',
        path: 'shop',
        beforeEnter: requireNoAuth,
        component: () => import('@/views/tabs/Tab2Page.vue')
      },
      {
        name: 'shop-contributors',
        path: 'shop/c',
        beforeEnter: requireNoAuth,
        component: () => import('@/views/shop/ContributorListPage.vue')
      },
      {
        name: 'shop-contributor',
        path: 'shop/c/:creator_id',
        beforeEnter: requireNoAuth,
        component: () => import('@/views/shop/ContributorPage.vue')
      },
      {
        name: 'shop-product',
        path: 'shop/p/:product_id',
        beforeEnter: requireNoAuth,
        component: () => import('@/views/shop/ProductPage.vue')
      },
      {
        name: 'mypage',
        path: 'mypage',
        beforeEnter: requireAuth,
        component: () => import('@/views/tabs/Tab3Page.vue')
      },
    ]
  },
  {
    name: 'signin',
    path: '/signin',
    beforeEnter: requireNoAuth, //ログイン中はitemに遷移させる
    component: () => import('@/views/auth/SigninPage.vue')
  },
  {
    name: 'signup',
    path: '/signup',
    beforeEnter: requireNoAuth, //ログイン中はitemに遷移させる
    component: () => import('@/views/auth/SignupPage.vue')
  },
  {
    name: 'reset-password',
    path: '/reset-password',
    beforeEnter: requireNoAuth,
    component: () => import('@/views/auth/ResetPasswordPage.vue')
  },
  {
    name: 'reset-password-confirm',
    path: '/reset-password-confirm',
    beforeEnter: requireNoAuth,
    component: () => import('@/views/auth/ResetPasswordConfirmPage.vue')
  },
  {
    name: 'profile-edit',
    path: '/profile/edit',
    beforeEnter: requireAuth,
    component: () => import('@/views/mypage/ProfileEditPage.vue')
  },
  {
    name: 'account-edit',
    path: '/account/edit',
    beforeEnter: requireAuth,
    component: () => import('@/views/auth/AccountEditPage.vue')
  },
  {
    name: 'cart',
    path: '/cart',
    beforeEnter: requireAuth,
    component: () => import('@/views/shop/CartPage.vue')
  },
  {
    path: "/callback",
    name: "callback",
    beforeEnter: requireNoAuth,
    component: () => import("@/views/auth/AuthCallbackPage.vue"),
  },
  /*
  {
    name: 'shopping',
    path: '/shopping',
    beforeEnter: requireAuth,
    component: () => import('@/views/shop/ShoppingPage.vue')
  },
  {
    name: 'shopping-complete',
    path: '/shopping_complete',
    beforeEnter: requireAuth,
    component: () => import('@/views/shop/ShoppingCompletePage.vue')
  },
  */
  {
    path: '/:catchAll(.*)',
    name: '404',
    beforeEnter: requireAuth,
    component: () => import("@/views/404Page.vue"),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

supabase.auth.onAuthStateChange((event, session) => {

  if (event == "PASSWORD_RECOVERY"){
    console.log("PASSWORD_RECOVERY")
  }
        
  if (event == "SIGNED_IN") {
    
    console.log("SIGNED_IN")
    state.user = {
      uid: session?.user.id,
      access_token: session?.access_token,
      refresh_token: session?.refresh_token,
    }
    state.isLogged = true;
    
    const routeName = router.currentRoute.value.name;
    console.log("routeName", routeName);
    if (routeName == "callback") {
      setTimeout(() => {
        return router.push({ name: "item" });
      }, 0);
    }
    
  }
});

export default router
