import { ref, toRaw } from "vue";
import useSupabaseAuth from "@/hooks/supabase/auth";

import { supabase } from '@/hooks/supabase/supabase'

const { state } = useSupabaseAuth()

const libraries: any = ref([])
const purchaseProducts: any = ref([])

const useItem = () => {

  const initialize = async () => {
    console.log("item:initialize / データを最新化する")
    console.log("item:libraries / データを最新化する")
    await getLibraries()
    console.log("item:purchaseProducts / データを最新化する")
    await getPurchaseProducts()
    console.log("item:getPurchaseProducts / ", purchaseProducts.value)
  }

  const getLibraries = async () => {
    const { data, error } = await supabase.from('v_my_libraries')
    .select(`*`)
    .eq('user_id', state.user.uid) //uuidで絞り込み
    libraries.value = data
  }

  const getPurchaseProducts = async () => {

    try {
      const { data, error } = await supabase.from('v_purchase_products')
        .select(`*`)
        .eq('user_id', state.user.uid) //uuidで絞り込み
        .order('created_at', { ascending: false }) //降順
      
        if(error) throw error
      purchaseProducts.value = data

    }catch(e){
      purchaseProducts.value = []
      console.log("purchaseProducts", e)
    }
  }

  return {
    libraries,
    purchaseProducts,
    initialize,
  };
  
}

export default useItem;