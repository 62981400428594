import {
  ref,
} from 'vue'
import { 
  loadingController,
} from '@ionic/vue';

const loading = ref()

export default function () {

  const setLoading = async (message = '') => {
    if(!loading.value){
      if(message !== ''){
        loading.value = await loadingController.create({
          spinner: 'circles',
          message: message,
          duration: 5000,
        })
      }else{
        loading.value = await loadingController.create({
          spinner: 'circles',
          duration: 5000,
        })
      }
      
      await loading.value.present();
    }
  }

  const delLoading = async () => {
    if(loading.value){
      await loading.value.dismiss();
      loading.value = null
    }
      
  }

  return {
    loading,
    setLoading,
    delLoading,
  };
}