import { toRefs, reactive, ref } from "vue";
import { supabase } from "@/hooks/supabase/supabase"
import { useRouter, useRoute } from 'vue-router'; // 追加

import { URLOpenListenerEvent } from '@capacitor/app';

const router = useRouter();
const route = useRoute();

type TState = {
  error: Error | null;
  user: any;
  userData: any;
  isLogged: boolean;
};

const state = reactive<TState>({
  user: null,
  error: null,
  userData: null,
  isLogged: false,
});

const snsAuth: any = ref({
  access_token: null,
  refresh_token: null,
  provider_token: null,
  isi: null,
  ibi: null,
  link: null,
})

const callBackUrlParts = ref()

const useAuth = () => {

  const updateProfile = async (access_token: string, refresh_token: string) => {
    
    try {

      const { data, error } = await supabase.auth.setSession({
        access_token: access_token,
        refresh_token: refresh_token
      });
  
      if (error) throw error;
      console.log("setSession", data)
      
      const { error: err02 } = await supabase.rpc('do_signup', {
        u: {
          id: data.user?.id,
          nickname: data.user?.user_metadata?.name
        }
      })
  
      if (err02) throw err02
      state.isLogged = true;
  
      return {
        type: 'oAuth'
      }

    }catch(e){
      //
    }
  }

  //ユーザデータを最新化する
  const getUserData = async (): Promise<boolean> => {

    console.log("auth:getUserData / データを最新化する")
    
    try {

      const { data, error } = await supabase.from('users')
      .select(`
        *,
        cart(id)
      `)
      .match({
        'id': state.user.uid,
        'cart.user_id': state.user.uid,
      }) //uuidで絞り込み
      //.eq('id', state.user.uid) //uuidで絞り込み
      .single()

      //エラーのとき
      if (error && error?.code !== 'PGRST116') throw error

      //データが存在していないエラー / APIアクセスは成功しているとき
      if (error?.code === 'PGRST116'){
        return false;
      }
      state.userData = data
      console.log(state.userData)
      return true;

    } catch (error) {
      state.error = error as Error;
      console.log(state.error)
      return false;
    }

  }

  const signin = async (email: string, password: string): Promise<void> => {
    
    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email: email,
        password: password,
      })
      if (error) throw error
      
      state.isLogged = true;
      console.log("login Data", data)
    } catch (error) {
      if (error instanceof Error) {
        console.log(error.message)
      }
      state.isLogged = false;
    } finally {
      //console.log("ログイン完了")
    }

  }

  const signout = async (): Promise<void> => {
    try{
      const { error } = await supabase.auth.signOut()
      console.log("signout error1: ", error)
      if (error) throw error
      state.error = null;
      state.user = null;
      state.isLogged = false;
      console.log("signout error2: ", error)
    } catch (error) {
      if (error instanceof Error) {
        console.log(error.message)
        state.error = error as Error;
      }
    }
  };

  const signup = async (username: string, email: string, password: string): Promise<boolean> => {

    try{
      
      //ここもSQLで記述できる / そうすれば全てトランザクション内に記述できるが...

      /*
      const { data, error: err01 } = await supabase.auth.signUp({
        email: email,
        password: password,
      })
      */

      const { data, error: err01 } = await supabase.auth.signUp({
        email: email,
        password: password,
        options: {
          data: {
            username: username,
            point: 3200,
          }
        }
      })

      if (err01) throw err01

      console.log("signup Data", data)

      const { error: err02 } = await supabase.rpc('do_signup', {
        u: {
          id: data.user?.id,
          nickname: username
        }
      })

      if (err02) throw err02
      return true;
      
      /*
        メール認証ONのときは returnする 
        return true;
      */
      state.isLogged = true;
      return true;

    } catch (error) {
      console.log(error);
      state.error = error as Error;
      state.isLogged = false;
      return false;
    }
  }

  const snsSignup = async (provider: 'google' | 'twitter' | 'facebook'): Promise<void> => {

    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: provider,
      options: {
        redirectTo: `${window.location.origin}/callback`,
      }
    })
    if (data) {
      state.isLogged = true;
      router.push("/item");
    }else if (error) {
      state.isLogged = false;
    }
    /*
    try {
      
      const { data, error } = await supabase.auth.signInWithOAuth({
        provider: provider,
        options: {
          //redirectTo: 'https://link.codama.fun/signup',
          redirectTo: 'https://app.codama.fun/signup',
        }
      })

      if(error) throw error
      console.log("data", data)
      
    }catch(e) {
      console.log(e)
    }
    */
  }

  const snsSignupCallback = async (to : string): Promise<any> => {

    //supabase callback URl : codama-fun://signup
    //Param 'isi' is not needed, since 'ifl' is already specified. Learn more.
    //event.url https://link.codama.fun/?link=https://link.codama.fun/signup&isi=6450153503&ibi=jp.co.wacha.codama-supabase&ifl=https://testflight.apple.com/v1/app/1673642059&cid=4211110296953405306&_osl=https://link.codama.fun/s4QR&_fpb=CKwGEPcCGgJqYQ==&_cpt=cpit&_iumenbl=1&_iumchkactval=1&_plt=1510&_uit=1356&_cpb=1

    /*
    if(!to){
      return {
        type: 'no-login'
      }
    }

    const hash = to.replace("#", "");
    console.log("これは", !to)

    hash.split("&").forEach((item: any) => {
      const [key, value] = item.split('=');
      switch (key) {
        case "access_token":
          snsAuth.value.access_token = value;
          break;
        case "refresh_token":
          snsAuth.value.refresh_token = value;
          break;
        case "provider_token":
          snsAuth.value.provider_token = value;
          break;
        case "link":
          snsAuth.value.link = value;
          break;
        case "isi":
          snsAuth.value.isi = value;
          break;
        case "ibi":
          snsAuth.value.ibi = value;
          break;
        default:
          break;
      }
    });
    */

    //現状 : iOSでのメール本人確認後のとき
    /*
    if(snsAuth.value.isi === '6450153503' && snsAuth.value.ibi === 'jp.co.wacha.codama-supabase'){
      return {
        type: 'email'
      }
    }
    */

    //現状 : SNSログインのとき
    /*
    try{
      
      const { data, error } = await supabase.auth.setSession({
        access_token: snsAuth.value.access_token,
        refresh_token:snsAuth.value.refresh_token
      });

      if (error) throw error;
      console.log("setSession", data)

      
      const { error: err02 } = await supabase.rpc('do_signup', {
        u: {
          id: data.user?.id,
          nickname: data.user?.user_metadata?.name
        }
      })

      if (err02) throw err02
      state.isLogged = true;

      return {
        type: 'oAuth'
      }
      

    }catch(error){
      console.log(error);
      state.error = error as Error;
      state.isLogged = false;
      return {
        type: 'error'
      }
    }
    */
  }

  return {
    state,
    signin,
    signout,
    signup,
    //authCheck,
    getUserData,
    snsSignup,
    snsSignupCallback,
  };

}

export default useAuth;