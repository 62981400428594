import {
  reactive,
  ref,
  toRaw,
} from 'vue'
import { Preferences } from '@capacitor/preferences';

type TState = {
  id: string;
  coverImage: string;
  title: string;
  src: string,
  artist: {
    id: string,
    name: string,
    description: string,
    image_url: string,
  }
};

type TStorage = {
  playHistory: Array<TState>,
}

const storage: TStorage = reactive({
  playHistory: [],
})

export default function () {

  const setStoragePlayHistory = async (option: TState) => {
      
    console.log("setStoragePlayHistory")

    storage.playHistory.unshift(toRaw(option))
    const history = Array.from(
      new Map(storage.playHistory.map((h) => [h.id, h])).values()
    );
    storage.playHistory = history
    await Preferences.set({
      key: 'playHistory',
      value: JSON.stringify(toRaw(history)),
    });

  }

  const getStoragePlayHistory = async () => {
   
    console.log("storage:getStoragePlayHistory / 再生履歴書取得")
    // await initStorage()
    const ret = await Preferences.get({ key: 'playHistory' });
    const ret10 = ret.value ? JSON.parse(ret.value as string) : []
    storage.playHistory = ret10.filter( (item: any, index: number) => {
      return index < 10;
    });
  }

  //初期化用 | getStorageの中で消す時だけ使用する
  const initStorage = async () => {
    console.log("再生履歴を初期化する")
    await Preferences.remove({ key: 'playHistory' })
    console.log("initStorage success")
  }

  return {
    storage,
    initStorage,
    setStoragePlayHistory,
    getStoragePlayHistory,
  };
}