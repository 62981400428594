import { createGesture } from '@ionic/vue';

export const DisableSwipeBackDirective = {
  beforeMount(el: any) {
    const gesture = createGesture({
      el,
      threshold: 0,
      disableScroll: false,
      gestureName: 'goback-swipe',
      gesturePriority: 10,  //おそらくrefresherより優先度が低くて、go-back-swipeより高い？
      onMove: (detail) => {
        // console.log(detail);
      }
    });
    gesture.enable(true);
  }
};
